<template>
    <div class="d-flex align-items-center text-nowrap">
        <template v-if="columnData.type == 'task'">
            <div class="card flex-row p-0">
                <select v-if="columnData.can_edit" v-model="status" class="select-status" :class="{
                    'select-status-open': status == 'open',
                    'select-status-blocked': status == 'blocked',
                    'select-status-done': status == 'done',
                    'select-status-progress': status == 'in_progress'
                }">
                    <option v-for="(name, statusId) in constants.tasksStatus" :key="statusId" :value="statusId">{{name}}</option>
                </select>

                <span v-else>
                    {{constants.tasksStatus[status]}}
                </span>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        rowId: Number,
        columnData: Object,
    },

    data() {
        return {
            status: this.columnData.status,
            watchStatus: true,
        }
    },

    watch: {
        columnData: {
            deep: true,
            handler() {
                this.watchStatus = false
                this.status = this.columnData.status

                this.$nextTick(() => {
                    this.watchStatus = true
                })
            }
        },

        status() {
            if (this.watchStatus) {
                this.$emit('action', {action: 'change-status', rowId: this.rowId, status: this.status})
            }
        }
    }
}
</script>

<style scoped>

</style>
